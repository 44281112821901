import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState }) {
	const [invoiceStartDate, setInvoiceStartDate] = useState(null);
	const [invoiceEndDate, setInvoiceEndDate] = useState(null);
	const [activatedDate, setActivatedDate] = useState("");
	const [transInvoice, setTransInvoice] = useState("");
	const [postID, setPostID] = useState("");
	const [advertiser, setAdvertiser] = useState("");
	const [invoiceNumber, setInvoiceNumber] = useState("");
	const [agentLeadID, setAgentLeadID] = useState("");
	const [promoCode, setPromoCode] = useState("");
	const [agentID, setAgentID] = useState("");
	const [agentName, setAgentName] = useState("");
	const [agentLeadName, setAgentLeadName] = useState("");

	const handleClear = () => {
		setInvoiceStartDate("");
		setInvoiceEndDate("");
		setAdvertiser("");
		setInvoiceNumber("");
		setAgentName("");
		setActivatedDate("");
		setTransInvoice("");
		setAgentLeadID("");
		setPromoCode("");
		setPostID("");
		setAgentLeadName("");
		setAgentID("");
	};

	const generateQuery = () => {
		let queryString = "";

		if (invoiceStartDate){ queryString += `&invoice_start_date=${invoiceStartDate}`}
		if (invoiceEndDate){ queryString += `&invoice_end_date=${invoiceEndDate}`}
		if (postID){ queryString += `&jobads_id=${postID}`}
		if (invoiceNumber){ queryString += `&invoice_no=${invoiceNumber}`}
		if (agentName){ queryString += `&agent_name=${agentName}`}
		if (agentID){ queryString += `&agent_id=${agentID}`}
		if (agentLeadID){ queryString += `&agent_lead_id=${agentLeadID}`}
		if (agentLeadName){ queryString += `&agent_lead_name=${agentLeadName}`}
		if (promoCode){ queryString += `&promo_code=${promoCode}`}
		if (advertiser){ queryString += `&advertiser=${advertiser}`}
		if (transInvoice){ queryString += `&invoice_date=${transInvoice}`}
		// havent support activate date
		// if (activatedDate){ queryString += `&activated_date=${activatedDate}`}

		return queryString;
	};

	return (
		<>
			<CustomModalBody>
				<Form id="billing-filter-form">
					<Row>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceStartDate">Start Date </Label>
								<Input
									type="date"
									placeholder="Enter Start Date"
									name="invoiceStartDate"
									id="invoiceStartDate"
									value={invoiceStartDate}
									onChange={(e) => {setInvoiceStartDate(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceEndDate"> </Label>
								<Input
									type="date"
									placeholder="Enter "
									name="invoiceEndDate"
									id="invoiceEndDate"
									value={invoiceEndDate}
									onChange={(e) => {setInvoiceEndDate(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="postID">Post ID </Label>
								<Input
									placeholder="Enter Post ID"
									name="postID"
									id="postID"
									value={postID}
									onChange={(e) => {setPostID(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="transInvoice">Transaction Invoice</Label>
								<Input
									type="date"
									placeholder="Enter Transaction Invoice"
									name="transInvoice"
									id="transInvoice"
									value={transInvoice}
									onChange={(e) => {setTransInvoice(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						{/* <Col lg={3}>
							<FormGroup>
								<Label for="activatedDate">Activated Date </Label>
								<Input
									placeholder="Enter Activated Date"
									name="activatedDate"
									id="activatedDate"
									value={activatedDate}
									onChange={(e) => {setActivatedDate(e.target.value)}}
								/>
							</FormGroup>
						</Col> */}
						<Col lg={3}>
							<FormGroup>
								<Label for="advertiser">Advertiser Name </Label>
								<Input
									placeholder="Enter Advertiser Name"
									name="advertiser"
									id="advertiser"
									value={advertiser}
									onChange={(e) => {setAdvertiser(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceNumber">Invoice No. </Label>
								<Input
									placeholder="Enter Invoice No."
									name="invoiceNumber"
									id="invoiceNumber"
									value={invoiceNumber}
									onChange={(e) => {setInvoiceNumber(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="promoCode">Promo Code </Label>
								<Input
									placeholder="Enter Promo Code"
									name="promoCode"
									id="promoCode"
									value={promoCode}
									onChange={(e) => {setPromoCode(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentID">Agent ID </Label>
								<Input
									placeholder="Enter Agent ID"
									name="agentID"
									id="agentID"
									value={agentID}
									onChange={(e) => {setAgentID(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentName">Agent Name </Label>
								<Input
									placeholder="Enter Agent Name"
									name="agentName"
									id="agentName"
									value={agentName}
									onChange={(e) => {setAgentName(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentLeadID">Agent Lead ID </Label>
								<Input
									placeholder="Enter Agent Lead ID"
									name="agentLeadID"
									id="agentLeadID"
									value={agentLeadID}
									onChange={(e) => {setAgentLeadID(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentLeadName">Agent Lead Name </Label>
								<Input
									placeholder="Enter Agent Lead Name"
									name="agentLeadName"
									id="agentLeadName"
									value={agentLeadName}
									onChange={(e) => {setAgentLeadName(e.target.value)}}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "danger",
						outline: true,
						content: "Clear All",
						onClick: () => handleClear(),
					},
					{
						color: "primary",
						content: "Submit",
						onClick: () => {
							const queryString = generateQuery();
							onSubmit(queryString);
							handleState({ filterQuery: queryString });
							onChangeHOC("showFilterModal", false);
							onChangeHOC("advertiserPages", 1);
						},
					},
				]}
			/>
		</>
	);
}
